import React, { ComponentProps, ReactNode } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer } from "@mui/material"
import { makeStyles } from 'tss-react/mui'

import Bar from 'core/components/molecules/Bar'

import Button from "mui/Button"

const useStyles = makeStyles()(() => ({
  bar: {
    justifyContent: 'flex-end'
  },
  closeButton: {
    minWidth: 0,
    padding: '8px 12px'
  }
}))

const Sidebar = ({ close }: { close: () => void }) => {
  const { classes: { closeButton, bar } } = useStyles()

  return (
    <Bar className={bar}>
      <Button variant="text" color="inherit" onClick={close} className={closeButton}>
        <FontAwesomeIcon icon={['far', 'times-circle']} style={{ fontSize: 13 }} />
      </Button>
    </Bar>
  )
}

const Sidepanel = ({ children, open, onClose }: { children: ReactNode, open: boolean, onClose: ComponentProps<typeof Sidebar>['close'] }) => {
  return (
    <Drawer anchor="right" open={open}>
      <Sidebar close={onClose} />
      {children}
    </Drawer>
  )
}

export default Sidepanel
import { QueryClient, QueryFunction } from '@tanstack/query-core'

import useQueryMethods from 'core/hooks/useQueryMethods'

type CustomQueryKey = [string, Record<string, string>, 'GET' | 'GET_BINARY']

const useCustomQueryClient = () => {
  const { get, getBinary } = useQueryMethods()

  const defaultQueryFn: QueryFunction = async ({ queryKey, pageParam = {} }) => {
    const [endpoint, params, method = 'GET'] = queryKey as CustomQueryKey

    if (method === 'GET') {
      return get(endpoint, { ...params, ...pageParam })
    } else {
      return getBinary(endpoint, { ...params, ...pageParam })
    }
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
        retry: false
      },
      mutations: {
        retry: false
      }
    }
  })

  return queryClient
}

export default useCustomQueryClient

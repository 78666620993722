import { Ref, forwardRef } from 'react'

import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  hostPrefixLight: {
    '& > .MuiTypography-root': {
      color: theme.palette.text.disabled
    }
  },
  hostPrefix: {
    marginRight: 0
  }
}))

const URLTextField = forwardRef((props: TextFieldProps, ref: Ref<HTMLDivElement>) => {
  const { classes, cx } = useStyles()
  return (
    <TextField
      {...props}
      ref={ref}
      InputProps={{
        startAdornment: (
          <InputAdornment classes={{ root: cx(classes.hostPrefix, { [classes.hostPrefixLight]: props.disabled }) }} position="start">
            https://
          </InputAdornment>
        )
      }}
    />
  )
})

URLTextField.displayName = 'URLTextField'
export default URLTextField

import { ReactNode } from 'react'

import { Container } from '@mui/material'

import NavMenu from 'core/components/NavBar'

const Layout = (props: { children: ReactNode }) => {
  return (
    <div>
      <NavMenu />
      <Container>{props.children}</Container>
    </div>
  )
}

export default Layout

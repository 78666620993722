import { useEffect } from 'react'

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { Navigate } from 'react-router-dom'

import useQueryMethod from 'core/hooks/useQueryMethods'
import useSessionStorage from 'core/hooks/useSessionStorage'

import Paths from 'Paths'

const CreateAccount = () => {
  const [loginEntry, setLoginEntry] = useSessionStorage('login_entry', false)
  const { get } = useQueryMethod()

  useEffect(() => {
    if (!loginEntry) {
      setLoginEntry(true)
      get(`/../login-azuread`).then(() => {
        setLoginEntry(true)
      })
    }
  }, [loginEntry])

  return <></>
}

const Login = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <CreateAccount />
        <Navigate to={Paths.customers} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>Logging in...</UnauthenticatedTemplate>
    </>
  )
}

export default Login

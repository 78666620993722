import React, { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Snackbar, IconButton, Alert, AlertColor } from '@mui/material'

const Notification = (props: { open: boolean; handleClose: () => void; content: string | ReactElement; severity: AlertColor }) => {
  const { open, handleClose, content, severity } = props

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <FontAwesomeIcon icon={['fas', 'xmark']} />
      </IconButton>
    </React.Fragment>
  )

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      // message={content}
      action={action}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {content}
      </Alert>
    </Snackbar>
  )
}

export default Notification

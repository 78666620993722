import React from 'react'

import { Hidden, useMediaQuery } from '@mui/material'
import { useTheme, Breakpoint } from '@mui/material/styles'

export const Narrow = ({ children }: { children: React.ReactNode }) =>
  <Hidden mdUp>{children}</Hidden>

export const Wide = ({ children }: { children: React.ReactNode }) =>
  <Hidden only={['xs', 'sm']}>{children}</Hidden>

export const useWide = (breakpoint: Breakpoint = 'md') => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(breakpoint))
}

import { useState, useEffect } from 'react'

import { useMsal } from '@azure/msal-react'

import { getGraphPhoto } from 'core/utils/graphUtils'

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<any>()
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userProfilePic, setUserProfilePic] = useState('')

  const { accounts } = useMsal()

  useEffect(() => {
    if (accounts.length > 0) {
      setCurrentUser(accounts[0])

      getGraphPhoto('../me')
        .then((resp: any) => {
          setUserProfilePic(URL.createObjectURL(resp))
        })
        .catch(() => setUserProfilePic(''))
    }
  }, [accounts])

  useEffect(() => {
    if (currentUser) {
      setUserName(currentUser.name || '')
      setUserEmail(currentUser.username || '')
    }
  }, [currentUser])

  return {
    currentUser,
    userName,
    userEmail,
    userProfilePic
  }
}

export default useCurrentUser

import { ReactNode, useEffect, useMemo } from 'react'

import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, LinearProgress } from '@mui/material'
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridPaginationModel,
  GridCallbackDetails,
  GridSortModel,
  GridPagination,
  GridToolbarContainer,
  GridSortDirection,
  GridSortItem
} from '@mui/x-data-grid'
import { makeStyles } from 'tss-react/mui'

import { LocalCustomerType } from 'core/components/CustomerProfile'
import Header from 'core/components/molecules/Header'

import useQueryString from 'core/hooks/useQueryString'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(2),
    width: '100%',
    boxSizing: 'border-box',
    minHeight: '100%',
    background: theme.palette.background.default,
    borderRadius: 'inherit',
    '& > div:last-child': {
      display: 'flex',
      flexFlow: 'column',
      height: '100%'
    }
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box'
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center'
  },
  pagination: {
    marginRight: '4px'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  cellContent: {
    '&, & *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}))

export const CellContent = ({ children }: { children: ReactNode }) => {
  const { classes } = useStyles()
  return <div className={classes.cellContent}>{children}</div>
}

const Heading = (props: { title: string; children?: ReactNode; icon?: [IconPrefix, IconName] }) => {
  const { classes } = useStyles()
  return (
    <Box className={classes.header}>
      <div className={classes.headerItem}>
        {props.icon?.length && <FontAwesomeIcon icon={props.icon} className={classes.icon} />}
        {props.title && (
          <Header>{props.title}</Header>
        )}
      </div>
      <div className={classes.headerItem}>{props.children}</div>
    </Box>
  )
}

type SortBy = 'NameAsc' | 'NameDesc' | 'AddedAsc' | 'AddedDesc' | 'UserAsc' | 'UserDesc' | 'NumContAsc' | 'NumContDesc'

type CustomerSortParams = {
  rowsPerPage: number | undefined
  page: number | undefined
  sortBy: SortBy
}

export const DataGrid = (props: { rows: LocalCustomerType[]; columns: GridColDef[]; count: number; loading: boolean }) => {
  const { classes } = useStyles()
  const { rows, columns, count, loading } = props
  const { queryParams, updateQueryParam } = useQueryString<CustomerSortParams>()
  const { page, rowsPerPage, sortBy } = queryParams

  const sortValues: { sortBy: SortBy; field: GridSortItem['field']; sort: GridSortDirection }[] = useMemo(
    () => [
      { sortBy: 'AddedDesc', field: 'addedDateTime', sort: 'desc' },
      { sortBy: 'AddedAsc', field: 'addedDateTime', sort: 'asc' },
      { sortBy: 'NameDesc', field: 'name', sort: 'desc' },
      { sortBy: 'NameAsc', field: 'name', sort: 'asc' },
      { sortBy: 'UserDesc', field: 'user', sort: 'desc' },
      { sortBy: 'UserAsc', field: 'user', sort: 'asc' },
      { sortBy: 'NumContDesc', field: 'numberContributorsAllowed', sort: 'desc' },
      { sortBy: 'NumContAsc', field: 'numberContributorsAllowed', sort: 'asc' }
    ],
    []
  )

  const { field, sort } = useMemo(() => sortValues.find((v) => v.sortBy === sortBy) || sortValues[0], [sortBy, sortValues])

  useEffect(() => {
    if (!rowsPerPage) {
      updateQueryParam({ rowsPerPage: 10, page: 1, sortBy: 'AddedDesc' })
    }
  }, [rowsPerPage, updateQueryParam])

  const onPaginationModelChange = (model: GridPaginationModel, details: GridCallbackDetails) => {
    const { reason } = details
    if (reason) {
      const { pageSize: rowsPerPage, page } = model
      updateQueryParam({ rowsPerPage, page: page + 1 })
    }
  }

  const handleSortModelChange = (model: GridSortModel) => {
    if (model[0]) {
      const { field, sort } = model[0]
      const { sortBy } = sortValues.find((v) => v.field === field && v.sort === sort) || {}
      updateQueryParam({ sortBy })
    }
  }

  const CustomToolbar = () => (
    <GridToolbarContainer className={classes.toolbar}>
      <div>{/* <GridToolbar /> */}</div>
      <div className={classes.pagination}>
        <GridPagination />
      </div>
    </GridToolbarContainer>
  )

  return (
    <MuiDataGrid
      autoHeight
      disableRowSelectionOnClick
      sx={{
        //disable cell outline
        '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
          outline: 'none'
        }
      }}
      rows={rows}
      columns={columns}
      rowCount={count}
      loading={loading}
      paginationModel={{ page: (page || 1) - 1, pageSize: rowsPerPage || 10 }}
      pageSizeOptions={[5, 10, 25, 50]}
      paginationMode="server"
      onPaginationModelChange={onPaginationModelChange}
      sortingMode="server"
      sortModel={[{ field, sort }]}
      sortingOrder={['desc', 'asc']}
      onSortModelChange={handleSortModelChange}
      slots={{
        loadingOverlay: LinearProgress,
        toolbar: CustomToolbar
      }}
    />
  )
}

const DataGridLayout = (props: { children?: ReactNode; className?: string }) => {
  const { cx, classes } = useStyles()
  return (
    <Box width={1} className={cx(classes.wrapper, props.className)}>
      {props.children}
    </Box>
  )
}

DataGridLayout.Heading = Heading
export default DataGridLayout

import React, { useState } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightFromBracket, faDiagramProject } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AccountCircle } from '@mui/icons-material'
import { Box, Menu, MenuItem, Avatar, Divider, ListItemIcon, IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import TokenModal from 'core/components/TokenModal'

import { logout } from 'core/hooks/useAuth'
import useCurrentUser from 'core/hooks/useCurrentUser'

import { capitalize } from 'core/helpers/string'

const useStyles = makeStyles()(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(46, 156, 189, 0.08)'
    }
  }
}))

const AccountMenu = () => {
  const { userName, userEmail, userProfilePic } = useCurrentUser()

  const { classes } = useStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [apiConsole, setApiConsole] = useState(false)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleOpenModal = () => setApiConsole(true)

  const handleCloseModal = () => setApiConsole(false)

  const handleLogout = async () => {
    try {
      await logout()
    } catch (error) {
      console.error('error during logout', error)
    }
  }
  const menuItems: { onClick: () => void; icon: IconDefinition; text: string }[] = [
    { onClick: handleOpenModal, icon: faDiagramProject, text: 'API console' },
    { onClick: handleLogout, icon: faArrowRightFromBracket, text: 'logout' }
  ]

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton className={classes.root} size="large" onClick={handleClick}>
          {userProfilePic ? <Avatar src={userProfilePic} alt="Profile" /> : <AccountCircle color="primary" fontSize="large" />}
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              }
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          {userProfilePic ? <Avatar src={userProfilePic} alt="Profile" /> : <AccountCircle color="primary" fontSize="large" />}
          <Box>
            <Typography>{userName}</Typography>
            <Typography color="textSecondary">{userEmail}</Typography>
          </Box>
        </MenuItem>

        <Divider />

        {menuItems.map(({ icon, onClick, text }) => (
          <MenuItem onClick={onClick} key={text}>
            <ListItemIcon>
              <FontAwesomeIcon icon={icon} />
            </ListItemIcon>
            {capitalize(text)}
          </MenuItem>
        ))}
      </Menu>
      <TokenModal open={apiConsole} onClose={handleCloseModal} />
    </div>
  )
}

export default AccountMenu

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fab } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<{ count: number }>()((theme, { count }) => ({
  root: {
    position: 'fixed',
    bottom: `${20 + 70 * count}px`,
    right: '30px',
    zIndex: 10
  }
}))

const FloatingButton = (props: { onClick: () => void; faIcon: IconDefinition; count?: number }) => {
  const { onClick, faIcon, count = 0 } = props
  const { classes } = useStyles({ count })
  return (
    <Fab className={classes.root} color="primary" onClick={onClick}>
      <FontAwesomeIcon icon={faIcon} />
    </Fab>
  )
}

export default FloatingButton

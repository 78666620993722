import { useEffect } from 'react'

import { InteractionType } from '@azure/msal-browser'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { QueryClientProvider } from '@tanstack/react-query'
import { Navigate, Route, Routes } from 'react-router-dom'

import CustomersPage from 'pages/customers'

import Login from 'core/components/Login'

import useCustomQueryClient from 'core/hooks/useCustomQueryClient'

import ErrorBoundary from 'core/ErrorBoundary'
import Layout from 'core/Layout'

import Paths from 'Paths'
import './fontawesome-library'

const App = () => {
  const queryClient = useCustomQueryClient()
  const loginType = window.self !== window.top ? InteractionType.Popup : InteractionType.Redirect

  const { login, error } = useMsalAuthentication(loginType)

  useEffect(() => {
    if (error) {
      login(loginType)
    }
  }, [error, loginType, login])

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthenticatedTemplate>
          <Layout>
            <Routes>
              <Route path={Paths.login} element={<Login />} />
              <Route path={Paths.customers} element={<CustomersPage />} />
              <Route path={Paths.home} element={<Navigate to={Paths.customers} replace />} />
            </Routes>
          </Layout>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Navigate to={Paths.login} />
        </UnauthenticatedTemplate>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

export default App

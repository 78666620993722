import { useMutation } from '@tanstack/react-query'

import { LocalCustomerType } from 'core/components/CustomerProfile'
import CustomersList, { Heading } from 'core/components/CustomersList'

import useCurrentUser from 'core/hooks/useCurrentUser'
import useListQuery, { SearchQueryListParam } from 'core/hooks/useListQuery'
import useQueryMethods from 'core/hooks/useQueryMethods'
import useQueryString from 'core/hooks/useQueryString'

type SortByOption = 'NameAsc' | 'NameDesc' | 'AddedAsc' | 'AddedDesc'

type CustomersPageParams = {
  sortBy?: SortByOption
} & SearchQueryListParam

const CustomersPage = () => {
  const { userEmail } = useCurrentUser()
  const { post, put, del } = useQueryMethods()
  const { queryParams } = useQueryString<CustomersPageParams>()
  const { sortBy = 'NameDesc' } = queryParams

  const { isLoading, items, total, refetch } = useListQuery<LocalCustomerType>('/customers', { sortBy })

  const { mutateAsync: addCustomer, isLoading: isAdding } = useMutation({
    mutationKey: ['addCustomer'],
    mutationFn: async (
      newCustomer: Pick<
        LocalCustomerType,
        'name' | 'domain' | 'deploymentUrl' | 'apiKey' | 'numberContributorsAllowed' | 'enabled' | 'showIntroductionsFeature' | 'showActivitiesStream'
      >
    ) => {
      return await post<Response>(`/customers`, { ...newCustomer, user: userEmail })
    },
    onSuccess: () => refetch()
  })

  const { mutateAsync: removeCustomer, isLoading: isRemoving } = useMutation({
    mutationKey: ['removeCustomer'],
    mutationFn: async (customerId: LocalCustomerType['id']) => {
      return await del<Response>(`/customers/${customerId}`)
    },
    onSuccess: () => refetch()
  })

  const { mutateAsync: editCustomer, isLoading: isEditing } = useMutation({
    mutationKey: ['editCustomer'],
    mutationFn: async ({
      id,
      name,
      domain,
      deploymentUrl,
      apiKey,
      numberContributorsAllowed,
      enabled,
      showIntroductionsFeature,
      showActivitiesStream
    }: Pick<
      LocalCustomerType,
      | 'id'
      | 'name'
      | 'domain'
      | 'deploymentUrl'
      | 'apiKey'
      | 'numberContributorsAllowed'
      | 'enabled'
      | 'showIntroductionsFeature'
      | 'showActivitiesStream'
    >) => {
      return await put<LocalCustomerType>(
        `/customers/${id}?name=${name}&domain=${domain}&deploymentUrl=${deploymentUrl}&apiKey=${apiKey}&numberContributorsAllowed=${numberContributorsAllowed}&enabled=${enabled}&user=${userEmail}&showIntroductionsFeature=${showIntroductionsFeature}&showActivitiesStream=${showActivitiesStream}`
      )
    },
    onSuccess: (data) => {
      refetch({
        refetchPage: (page: { data: LocalCustomerType[]; count: number }) => {
          return !!page.data.find(({ id }) => id === data?.id)
        }
      })
    }
  })

  return (
    <CustomersList
      heading={<Heading add={addCustomer} saving={isAdding} />}
      items={items}
      loading={isLoading}
      saving={isAdding || isRemoving || isEditing}
      count={total ?? 0}
      remove={removeCustomer}
      edit={editCustomer}
    />
  )
}

export default CustomersPage

import { ReactElement } from 'react'

import { Button, Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'

import LoadingButton from 'core/components/molecules/LoadingButton'

type Footer = { footer: ReactElement; onCancel?: never; onOk?: never; cancelText?: never; okText?: never }
type Default = { onCancel: () => void; onOk: () => void; cancelText?: string; okText?: string; footer?: never }

const Dialog = (
  props: { open: boolean; title: string; content: string | ReactElement; loading: boolean; onBackdropClick: () => void } & (Footer | Default)
) => {
  const { title, content, open, footer, onCancel, onOk, onBackdropClick, cancelText, okText, loading } = props

  return (
    <MuiDialog open={open} onClose={onBackdropClick}>
      <DialogTitle variant="h3">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {footer || (
          <>
            <Button onClick={onCancel} disabled={loading}>
              {cancelText || 'Cancel'}
            </Button>
            <LoadingButton onClick={onOk} loading={loading}>
              {okText || 'Ok'}
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog

import { ReactNode } from 'react'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  bar: {
    height: 28,
    position: 'sticky',
    background: theme.palette.background.paper,
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1000
  }
}))

const Bar = (props: { children: ReactNode; className?: string }) => {
  const { classes, cx } = useStyles()
  return <div className={cx(classes.bar, props.className)}>{props.children}</div>
}

export default Bar

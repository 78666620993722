import { useState } from "react"

import { AlertColor } from "@mui/material";

type NotificationType = { message: string; status: AlertColor; opened: boolean }

const useSnackbar = () => {
  const [notification, setNotification] = useState<NotificationType>({ message: '', status: 'info', opened: false })
  const { message, status, opened } = notification

  const openSuccess = (message: string) => {
    setNotification({ message, status: 'success', opened: true })
  }

  const openError = (errorMessage: string) => {
    setNotification({ message: errorMessage, status: 'error', opened: true })
  }

  const close = () => {
    setNotification((prevState) => ({ ...prevState, opened: false }))
  }

  return {
    opened,
    status,
    message,
    openSuccess,
    openError,
    close
  }
}

export default useSnackbar;
import { ReactNode } from "react"

import { Typography } from "@mui/material"

const Header = ({ children }: { children: ReactNode }) => {
  return (
    <Typography variant="h2">
      {children}
    </Typography>)
}

export default Header;
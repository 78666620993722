import { AppBar, Toolbar, Typography } from '@mui/material'

import AccountMenu from 'core/components/AccountMenu'

const NavBar = () => {
  return (
    <AppBar component="nav">
      <Toolbar>
        <Typography variant="h6" component="div" color="primary" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
          <img src={'/images/Transparent/logo-full-h50-clear.png'} alt="Logo" width="115" />
        </Typography>
        <AccountMenu />
      </Toolbar>
    </AppBar>
  )
}

export default NavBar

import { useState } from "react"

function useModal(closedState?: boolean): { modalOpened: boolean, closeModal: () => void, openModal: () => void }
function useModal<T extends boolean>(closedState: T): { modalOpened: T, closeModal: () => void, openModal: () => void }
function useModal<T>(closedState: T): { modalOpened: T, closeModal: () => void, openModal: (params: T) => void }

function useModal<T>(closedState?: T) {
  const [modalOpened, setModalOpened] = useState(closedState)

  const closeModal = () => {
    setModalOpened(closedState)
  }

  return {
    modalOpened,
    openModal: typeof closedState === 'undefined' || typeof closedState === 'boolean' ? () => setModalOpened(true as T) : (params: T) => setModalOpened(params),
    closeModal
  }
}

export default useModal
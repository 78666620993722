import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router-dom'

const useQueryString = <T = { [key: string]: number | string }>() => {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const queryParams = queryString.parse(search, { parseNumbers: true }) as T

  const getQueryParam = (param: keyof typeof queryParams) => {
    const queryParams = queryString.parse(search, { parseNumbers: true }) as T
    return queryParams[param]
  }

  const updateQueryParam = (params: Partial<T>) => {
    const currentQueryParams = queryString.parse(search)
    const newQueryParams = { ...currentQueryParams, ...params }

    const url = queryString.stringifyUrl(
      {
        url: pathname,
        query: newQueryParams
      },
      { skipEmptyString: true }
    )

    navigate(url, { replace: true })
  }

  return { queryParams, getQueryParam, updateQueryParam }
}

export default useQueryString

import { Component, ReactNode } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Theme } from '@mui/material/styles'
import { withStyles } from 'tss-react/mui'

import Empty from 'core/components/Empty'

import Button from 'mui/Button'

interface ErrorBoundaryProps {
  children: ReactNode
  classes?: Record<string, string>
}

interface ErrorBoundaryState {
  hasError: boolean
  error: ErrorEvent | null
  rejectError: PromiseRejectionEvent | null
}

const styles = (theme: Theme) => ({
  container: {
    maxWidth: 540,
    margin: '0 auto',
    padding: theme.spacing(2)
  },
  refresh: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(1)
    }
  }
})

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: null,
    rejectError: null
  }

  static getDerivedStateFromError(error: ErrorEvent): ErrorBoundaryState {
    return { hasError: true, rejectError: null, error }
  }

  catchErrors = (event: ErrorEvent) => {
    this.setState({ hasError: true, error: event })
  }

  catchUnhandledRejection = (event: PromiseRejectionEvent) => {
    this.setState({ hasError: true, rejectError: event })
  }

  componentDidMount() {
    window.addEventListener('error', this.catchErrors)
    window.addEventListener('unhandledrejection', this.catchUnhandledRejection)
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.catchErrors)
    window.removeEventListener('unhandledrejection', this.catchUnhandledRejection)
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps, prevState: ErrorBoundaryState) {
    if (this.state.hasError && !prevState.hasError) {
      this.postError()
    }

    // if (prevProps.location.pathname !== this.props.location.pathname) {
    //   this.setState({ hasError: false, error: null })
    // }
  }

  postError = () => {
    return null
  }

  render() {
    const { hasError, rejectError } = this.state
    const { children } = this.props

    const classes = withStyles.getClasses(this.props)

    const customErrors: CustomErrorType = {
      signInError: {
        title: 'Sign in error',
        subTitle: 'We can not sign in into your account. Please try to logout and sign in again.',
        action: <></>
      }
    }

    const errorType: keyof CustomErrorType = rejectError?.reason?.type
    const {
      title = 'Unexpected error',
      subTitle = 'There was an unexpected error while rendering this screen.',
      action: Actions = () => <></>
    } = errorType ? customErrors[errorType] : {}

    if (hasError) {
      return (
        <Empty
          className={classes.container}
          title={title}
          subTitle={
            <>
              {subTitle} If the issue persists, please reach out to{' '}
              <Button<'a'> href={`mailto:team@dotalign.com`} variant="link" component="a" disablePX>
                team@dotalign.com
              </Button>
            </>
          }
          icon={<FontAwesomeIcon icon={['fas', 'exclamation-triangle']} color="#F5A91C" size="4x" style={{ opacity: 0.5, marginTop: 8 }} />}
          action={<></>}
        />
      )
    }

    return children
  }
}

export default withStyles(ErrorBoundary, styles)

import { useEffect, useRef, useState } from 'react'

import { Typography, Snackbar } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import useAuth from 'core/hooks/useAuth'

import Dialog from 'mui/Dialog'

const useStyles = makeStyles()((theme) => ({
  inputBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  tokenContainer: {
    overflowY: 'scroll',
    padding: 4,
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.text.primary,
    wordBreak: 'normal',
    wordWrap: 'break-word',
    boxSizing: 'border-box'
  }
}))

const TokenModal = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props

  const { classes } = useStyles()

  const [showSnackbar, setShowSnackbar] = useState(false)
  const tokenRef = useRef(null)

  const [token, setToken] = useState('')
  const { getToken } = useAuth()

  useEffect(() => {
    async function get() {
      getToken().then((resp: any) => setToken(resp.accessToken))
    }
    get()
  }, [])

  const copyToken = async () => {
    if (tokenRef != null && tokenRef.current != null) {
      const tokenText = 'Bearer ' + token

      try {
        await navigator.clipboard.writeText(tokenText)
        setShowSnackbar(true)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleCloseSnackbar = () => setShowSnackbar(false)

  const goToSwagger = () => window.open('/swagger/index.html', '_blank')

  return (
    <>
      <Dialog
        open={open}
        title="Authorization token"
        content={
          <div className={classes.inputBox}>
            <Typography variant="body1" className={classes.tokenContainer} ref={tokenRef}>
              Bearer {token}
            </Typography>
          </div>
        }
        cancelText="copy token"
        onCancel={copyToken}
        onBackdropClick={onClose}
        okText="open api console"
        onOk={goToSwagger}
        loading={false}
      />

      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        key="token-snackbar"
        open={showSnackbar}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message="Toked copied in Clipboard"
      />
    </>
  )
}

export default TokenModal

import { createTheme } from '@mui/material'

const globalTheme = createTheme({
  palette: {
    primary: {
      main: '#1B95BB',
      light: '#EAF5FE'
    },
    text: {
      primary: '#202020',
      secondary: '#979797',
      disabled: '#DDDDDD',
      hint: 'rgba(0, 0, 0, 0.6)'
    },
    error: {
      main: '#EF4B23'
    },
    success: {
      main: '#4AA61D'
    },
    warning: {
      main: '#F5A91C'
    }
  },
  typography: {
    fontFamily: ['"Source Sans Pro"', '"Open Sans"', 'sans-serif'].join(','),
    h1: {
      fontSize: 32,
      lineHeight: '51px'
    },
    h2: {
      fontSize: 24,
      lineHeight: '38px'
    },
    h3: {
      fontSize: 20,
      lineHeight: '32px'
    },
    h4: {
      fontSize: 16,
      lineHeight: '26px'
    },
    h5: {
      fontSize: 12,
      lineHeight: '18px'
    },
    h6: {
      fontSize: 10,
      lineHeight: '16px'
    },
    body1: {
      fontSize: 14,
      lineHeight: '22px'
    },
    subtitle1: {
      fontSize: 15,
      lineHeight: '24px'
    },
    caption: {
      textTransform: 'uppercase',
      fontSize: 14
    }
  }
})

const theme = createTheme(
  {
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: '#fafafa',
            position: 'sticky',
            boxShadow: 'none'
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: 0,
            marginRight: 24,
            minWidth: 'auto'
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      }
    }
  },
  globalTheme
)

export default theme

declare module '@mui/material/styles' {
  interface TypeText {
    hint: string
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    link: true
  }
}

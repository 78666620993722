import * as _ from 'lodash'

export const apiHost =
  process.env.NODE_ENV === 'development' && process.env.REACT_APP_CLOUDHUB_API_HOST && window.location.protocol !== 'https:'
    ? process.env.REACT_APP_CLOUDHUB_API_HOST
    : ''

const localSettings = {
  settingsApi: `${apiHost}${(window as any)?.GlobalConfig?.SettingsApi || '/api/settings'}`,
  apiPrefix: `${apiHost}${(window as any)?.GlobalConfig?.Api || `/api`}`
}

const isIE = false || !!(document as any).documentMode

export default _.memoize(async function () {
  const result = await fetch(localSettings.settingsApi)
    .then((data: any) => {
      return data.json()
    })

    .catch((error) => {
      console.log('settingsApi', error)
      const errorObj = {
        requestInfo: `settingsApi`,
        error: error
      }
      throw errorObj
    })
  if (!result) {
    window.location.href = '/maintenance.html'
    return null
  } else {
    return {
      tenantId: result.tenantId,
      resourceId: result.clientId,
      clientId: result.clientId,
      apiPrefix: localSettings.apiPrefix,
      msalConfiguration: {
        auth: {
          clientId: result.clientId,
          authority: `https://login.microsoftonline.com/${result.tenantId}`,
          redirectUri: `${window.location.origin}/aad-auth`
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE
        }
      }
    }
  }
})

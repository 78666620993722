import React from 'react'

import MuiButton, { ButtonProps as MuiButtonProps, ButtonTypeMap } from '@mui/material/Button'
import { makeStyles } from 'tss-react/mui'

export type DisablePaddingType = AddPrefix<'disable', 'Padding' | 'PY' | 'PX' | 'PR' | 'PL' | 'PT' | 'PB'>

const useStyles = makeStyles<{ bold?: boolean }>()((theme, props) => ({
  label: {
    fontWeight: props?.bold ? 600 : 500
  },
  rounded: {
    borderRadius: theme.spacing(3)
  },
  link: {
    verticalAlign: 'unset',
    '&:hover': {
      background: 'transparent'
    },
    textTransform: 'initial'
  },
  widget: {
    background: '#EAF5FE',
    '&$disabled': {
      background: '#fff'
    },
    letterSpacing: '0.15px',
    textTransform: 'none'
  },
  disabled: {},
  disablePadding: {
    margin: theme.spacing(-1)
  },
  disablePX: {
    marginRight: theme.spacing(-1),
    marginLeft: theme.spacing(-1)
  },
  disablePL: {
    marginLeft: theme.spacing(-1)
  },
  disablePR: {
    marginRight: theme.spacing(-1)
  },
  disablePY: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1)
  },
  disablePT: {
    marginTop: theme.spacing(-1)
  },
  disablePB: {
    marginBottom: theme.spacing(-1)
  }
}))

type Variant = MuiButtonProps['variant'] | 'link' | 'widget'

export type ButtonProps<C extends React.ElementType = ButtonTypeMap['defaultComponent']> = Modify<
  MuiButtonProps<C, { component?: C; form?: 'default' | 'rounded'; bold?: boolean } & { [key in DisablePaddingType]?: boolean }>,
  { variant?: Variant }
>

export const disablePaddingsValues = ['disablePadding', 'disablePY', 'disablePX', 'disablePR', 'disablePL', 'disablePT', 'disablePB']

const Button = <C extends React.ElementType = ButtonTypeMap['defaultComponent']>(props: ButtonProps<C>) => {
  const { bold = true, variant = 'outlined', form, disablePadding, disablePY, disablePX, disablePR, disablePL, disablePT, disablePB, ...rest } = props

  const { classes, cx } = useStyles({ bold })

  return (
    <MuiButton
      {...rest}
      classes={{
        root: cx(
          { [classes.rounded]: form === 'rounded', [classes.widget]: variant === 'widget' },
          disablePaddingsValues.map((key) => ({ [classes[key as keyof typeof classes]]: props[key] }))
        ),
        label: classes.label,
        text: cx({ [classes.link]: variant === 'link' }),
        disabled: classes.disabled,
        ...props.classes
      }}
      variant={variant === 'link' || variant === 'widget' ? 'text' : variant}
    >
      {props.children}
    </MuiButton>
  )
}

export default Button

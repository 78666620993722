import { ReactElement } from 'react'

import MUISkeleton, { SkeletonProps as MuiSkeletonProps } from '@mui/material/Skeleton'

const Skeleton = (props: MuiSkeletonProps & { condition: boolean; children: ReactElement; fullWidth?: boolean }) => {
  const { condition, children, fullWidth, ...skProps } = props
  return props.condition ? (
    <MUISkeleton {...skProps} animation="wave" width={fullWidth ? '100%' : 'fit-content'}>
      {children}
    </MUISkeleton>
  ) : (
    children
  )
}

export default Skeleton

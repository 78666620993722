import useAuth, { defaultSettings } from 'core/hooks/useAuth'

import { mergeUrlWithParams } from 'core/utils/httpUtils'

import config from 'config'

type UrlParams<T = string> = { [param: string]: T | undefined }
class InvalidTokenError extends Error {}

const useQueryMethods = () => {
  const { getToken } = useAuth()

  const request = async <T>(input: RequestInfo, init?: RequestInit, binary?: boolean): Promise<T | undefined> => {
    let transformedInput: RequestInfo
    const settings = await config()

    if (typeof input === 'string') {
      if (input.indexOf('http') === 0) {
        transformedInput = input
      } else {
        transformedInput = `${settings?.apiPrefix}${input}`
      }
    } else {
      transformedInput = input
    }
    const options = (token: string) => {
      const result = {
        ...defaultSettings,
        ...init
      }

      result.headers = {
        ...result.headers,
        Authorization: `Bearer ${token}`
      }

      return result
    }

    try {
      const resp = await getToken()

      const response = await fetch(transformedInput, options(resp.accessToken))
      ;(window as any).Cookies?.set('accessToken', resp.accessToken)
      if (!binary) {
        const text = await response.text()

        if (response.ok) {
          try {
            const jsonData = JSON.parse(text) as T
            return jsonData
          } catch {
            return
          }
        } else {
          const errorObj = {
            error: JSON.parse(text),
            status: response.status
          }
          throw errorObj
        }
      } else {
        const blob = await response.blob()
        if (response.ok) {
          return blob as unknown as T
        } else {
          const errorObj = {
            error: response.statusText,
            status: response.status
          }
          throw errorObj
        }
      }
    } catch (err: any) {
      if (err.status === 503) {
        window.location.href = '/maintenance.html'
      }

      if (err.error && err.error.name !== 'AbortError' && !(err instanceof InvalidTokenError)) {
        throw err
      }
    }
  }

  const get = <T>(url: string, params?: UrlParams): Promise<T | undefined> => {
    return request<T>(mergeUrlWithParams(url, params), {
      method: 'GET'
    })
  }

  const post = <T>(url: string, data?: unknown, params?: UrlParams): Promise<T | undefined> => {
    return request<T>(mergeUrlWithParams(url, params), {
      method: 'POST',
      body: transformBody(data)
    })
  }

  const postBinary = (url: string, data?: unknown, params?: UrlParams): Promise<Blob | undefined> => {
    return request<Blob>(
      mergeUrlWithParams(url, params),
      {
        method: 'POST',
        body: transformBody(data)
      },
      true
    )
  }

  const postRaw = <T>(url: string, data?: any, params?: UrlParams): Promise<T | undefined> => {
    return request<T>(mergeUrlWithParams(url, params), {
      method: 'POST',
      body: data,
      headers: {
        ContentType: 'multipart/form-data'
      }
    })
  }

  const getBinary = (url: string, params?: UrlParams): Promise<Blob | undefined> => {
    return request<Blob>(
      mergeUrlWithParams(url, params),
      {
        method: 'GET'
      },
      true
    )
  }

  const patch = <T>(url: string, data?: unknown, params?: UrlParams): Promise<T | undefined> => {
    return request<T>(mergeUrlWithParams(url, params), {
      method: 'PATCH',
      body: transformBody(data)
    })
  }

  const put = <T>(url: string, data?: unknown, params?: UrlParams): Promise<T | undefined> => {
    return request<T>(mergeUrlWithParams(url, params), {
      method: 'PUT',
      body: transformBody(data)
    })
  }

  const del = <T>(url: string, data?: unknown, params?: UrlParams): Promise<T | undefined> => {
    return request<T>(mergeUrlWithParams(url, params), {
      method: 'DELETE',
      body: transformBody(data)
    })
  }

  const transformBody = (data: unknown): string => {
    return JSON.stringify(data)
  }

  return {
    get,
    post,
    postBinary,
    postRaw,
    getBinary,
    patch,
    put,
    del
  }
}

export default useQueryMethods

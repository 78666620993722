export const addMissedProtocol = (url: string) => {
  const newUrl = url.replace(/^http:\/\//i, 'https://')

  if (url && !/^(?:f|ht)tps?:\/\//.test(newUrl)) {
    return `https://${url}`
  }
  return newUrl
}

export const getRidOfProtocol = (url: string) => {
  const newUrl = url.replace(/^http:\/\//i, 'https://')
  const result = (newUrl.match(/^(ht)tps?:\/\/(.*)/) || [])[2]
  return result || url
}

export const validUrl = (url: string) => {
  return url && /^[A-Z0-9.-]+\.[A-Z]{2,4}\/?$/i.test(url)
}

export const validDomain = (domain: string) => {
  return domain && /^[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(domain)
}

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
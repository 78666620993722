import { ReactElement } from 'react'

import { Box, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
    height: '100%',
    boxSizing: 'border-box',
    padding: `${theme.spacing(2)}px 0`,
    borderRadius: '4px',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF'
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    overflow: 'hidden'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    color: theme.palette.text.hint,
    fontSize: '18px',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px 0 ${theme.spacing(5)}px`
    }
  },
  title: {
    color: theme.palette.text.hint,
    fontWeight: 600,
    textAlign: 'center',
    '&, & > *': {
      fontSize: '18px',
      verticalAlign: 'unset',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    }
  },
  subTitle: {
    color: theme.palette.text.hint,
    fontWeight: 100,
    textAlign: 'center',
    wordBreak: 'break-word',
    '&, & *': {
      fontSize: '18px',
      verticalAlign: 'unset',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    }
  },
  imageStub: {
    height: '91px',
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.7, 0.7)'
    }
  }
}))

const Empty = (props: {
  title?: string | ReactElement
  subTitle?: string | ReactElement
  icon?: ReactElement
  action?: ReactElement
  className?: string
}) => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.root, props.className)}>
      <Box className={classes.placeholder}>
        {props.icon || <img src="/image/data-source/data-source.png" className={classes.imageStub} alt="empty" />}
      </Box>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>{props.title}</Typography>
        <Typography className={classes.subTitle}>{props.subTitle}</Typography>
        {props.action}
      </Box>
    </Box>
  )
}

export default Empty

import { useEffect, ReactNode } from 'react'

import { Box, CircularProgress } from '@mui/material'
import ReactInfiniteScroll from 'react-infinite-scroll-component'
import { makeStyles } from 'tss-react/mui'

import useQueryString from 'core/hooks/useQueryString'

const useStyles = makeStyles()((theme) => ({
  root: {
    overflow: 'unset !important'
  },
  progress: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center'
  }
}))

const InfiniteScroll = ({ dataLength, count, children }: { dataLength: number; count: number; children: ReactNode }) => {
  const { classes } = useStyles()

  const { queryParams, updateQueryParam } = useQueryString()
  const { page, rowsPerPage } = queryParams as { rowsPerPage: number | undefined; page: number | undefined }

  useEffect(() => {
    if (!rowsPerPage) {
      updateQueryParam({ rowsPerPage: 10, page: 1 })
    }
  }, [rowsPerPage, updateQueryParam])

  const handleNext = () => {
    if (rowsPerPage && page) {
      updateQueryParam({ rowsPerPage, page: page + 1 })
    }
  }

  return (
    <ReactInfiniteScroll
      className={classes.root}
      dataLength={dataLength}
      next={handleNext}
      hasMore={dataLength < count}
      loader={
        <Box className={classes.progress}>
          <CircularProgress color="primary" />
        </Box>
      }
      scrollThreshold={0.9}
    >
      {children}
    </ReactInfiniteScroll>
  )
}

InfiniteScroll.displayName = 'InfiniteScroll'

export default InfiniteScroll

export type UrlParams<T = string> = {
  [param: string]: T | undefined
}

export const mergeUrlWithParams = (url: string, params?: UrlParams<string | string[]>): string => {
  if (!params || !Object.keys(params).length) {
    return encodeURI(url)
  } else {
    const parameters: string[] = []
    for (const key in params) {
      const param = params[key]
      if (param && typeof param === 'string') {
        parameters.push(key + '=' + encodeURIComponent(param))
      }
      if (param && Array.isArray(param)) {
        param.forEach((p) => parameters.push(key + '=' + encodeURIComponent(p)))
      }
    }
    const fullUrl = parameters.length ? url + '?' + parameters.join('&') : url
    return fullUrl
  }
}
import { Avatar } from '@mui/material'

const CustomerLogo = (props: { url: string; size: 'small' | 'medium' | 'large' }) => {
  const { url, size } = props

  const avatarSize = {
    small: { width: 27, height: 27 },
    medium: { width: 30, height: 30 },
    large: { width: 35, height: 35 }
  }

  const logo = `//dotaligncloudhub.azurewebsites.net/logos/${url}?v2`

  return <Avatar src={logo} sx={{ ...avatarSize[size] }} />
}

export const CustomLogo = (props: { imageData: any }) => {
  const { imageData } = props

  return (
    <div>
      <img src={`data:image/jpeg;base64,${imageData}`} style={{ width: '100%', height: 'auto' }} />
    </div>
  )
}

export default CustomerLogo

import React from 'react'

import { Grid, Stack } from '@mui/material'

interface PageProps {
  children: React.ReactNode
}

const Page: React.FC<PageProps> = ({ children }) => {
  return (
    <Grid container alignItems="center" justifyContent="center" height="100vh">
      <Grid item xs={12}>
        <Stack sx={{ p: 2 }}>{children}</Stack>
      </Grid>
    </Grid>
  )
}

export default Page

import { useRef, useEffect } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import { useWide } from 'core/components/layout'

import useQueryMethods from 'core/hooks/useQueryMethods'

export type SearchQueryListParam = {
  page: number
  rowsPerPage: number
  searchTerm?: string
}

const useListQuery = <T>(url: string, queryParams: { [key: string]: string } = {}) => {
  const wide = useWide()
  const { get } = useQueryMethods()
  const { search } = useLocation()
  const { page, rowsPerPage, searchTerm } = queryString.parse(search, { parseNumbers: true }) as SearchQueryListParam

  const currentPageParam = { skip: `${(page - 1) * rowsPerPage}`, take: `${rowsPerPage}`, search: searchTerm }
  const intialPageParam = useRef<{ skip: string; take: string; search?: string }>()

  useEffect(() => {
    if (!intialPageParam.current && typeof page !== 'undefined') {
      intialPageParam.current = wide ? currentPageParam : { skip: `0`, take: `${rowsPerPage * page}`, search: searchTerm }
    }
  }, [search, wide])

  const { isLoading, data, refetch, fetchNextPage } = useInfiniteQuery({
    queryKey: [url, wide ? { ...currentPageParam, ...queryParams } : queryParams],
    queryFn: async ({ queryKey, pageParam = intialPageParam.current }) => {
      const [endpoint, params] = queryKey as [string, Record<string, string>]
      return get<{ data: T[]; count: number }>(endpoint, { ...params, ...(!wide ? pageParam : {}) })
    },
    enabled: typeof page === 'number' && typeof rowsPerPage === 'number',
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (page > 1 && !wide) {
      fetchNextPage({ pageParam: currentPageParam })
    }
  }, [page])

  const items = wide ? data?.pages[0]?.data : data?.pages.flatMap((cData) => cData?.data || [])

  return {
    items,
    total: data?.pages[0]?.count,
    isLoading,
    refetch
  }
}

export default useListQuery
